// Variables - primary-color, secondary-color, default-text-color will be overridden by values returned from api
:root {
  --primary-color: #005ab7;
  --secondary-color: #ebf6fd;
  --tertiary-color: #009300;
  --white-color: #fff;
  --black-color: #000;
  --default-text-color: var(--black-color);
  --question-text-color: var(--primary-color);
  --active-border-color: var(--white-color);
  --active-text-color: var(--white-color);
  --active-button-background-color: var(--tertiary-color);
  --active-background-color: var(--primary-color);
  --active-secondary-button-background-color: var(--primary-color);
  --border-color: var(--primary-color);
  --disabled-button-background-color: #aaa;
  --required-background-color: #ffe100;
  --section-background-color: var(--secondary-color);
  --select-background-color: var(--white-color);
  --primary-button-background-color: var(--primary-color);
}

html {
  font-size: 16px;
}

body {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: var(--default-text-color);
}

a {
  color: var(--primary-color);
}

img {
  max-width: 100%;
  width: 100%;
}

.container {
  padding: 2em 1em;
  max-width: 90%;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.has-background {
  background-color: var(--section-background-color);
}

.action {
  display: flex;
  justify-content: center;
}